.contact-hero {
    background-image: url("../../../public/assets/contactimages/contactHero.png");
    background-repeat: no-repeat;
    min-height: 90vh;
    position: relative;
    background-size: cover;
    background-position: right;
    overflow: hidden;
}

.contact-hero h1{
    font-weight: 600;
    font-size: 3rem;
    line-height: 4.5rem;
    word-spacing: 0.5rem;
}

.color-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
}

.handshaketext{
    font-size: 1.4rem;
    font-weight: 400;
}


/* TABLET MEDIA QUERY */

@media only screen and (max-width: 768px) {
    .contact-hero {
        min-height: 50vh;
        background-position: right;
    }

    .contact-hero h1{
        font-size: calc(0.8rem + 2.3vw);
        line-height: 3rem;
        word-spacing: 0.4rem;
    } 

    .handshaketext{
        font-size: 1rem;
    }

    .whywedo {
        height: auto;
    }
  }

/* MOBILE MEDIA QUERY */

  @media only screen and (max-width: 576px) {

    .contact-hero h1{
        font-size: calc(0.8rem + 2.3vw);
        line-height: 3rem;
        word-spacing: 0rem;
    }

    .handshaketext{
        font-size: 0.9rem;
        line-height: 1.5rem;
    }

    .whywedo{
        height: auto;
    }
}
