.career-hero {
  background-image: url("../../../public/assets/careerImages/careerHero.png");
  background-repeat: no-repeat;
  min-height: 90vh;
  position: relative;
  background-size: cover;
  background-position: right;
  overflow: hidden;
}

.career-hero h1 {
  font-weight: 600;
  font-size: 3rem;
  line-height: 4.5rem;
  word-spacing: 0.5rem;
}

.color-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}

.heading {
  font-weight: 600;
  font-size: 2.5rem;
  font-family: "Open Sans";
}

.backbox {
  background-color: #663344;
  border-radius: 3.125rem;
  
}

.firstLine {
  font-size: 1.875rem;
  font-weight: 700;
  font-family: "Open Sans";
}

.paragraph {
  font-size: 1.25rem;
  font-family: "open Sans";
}

.newspan {
  font-weight: 600;
  font-family: "Open Sans";
}

.clickMe {
  font-weight: 700;
  font-size: 1.25rem;
  border-radius: 0.625rem;
  font-family: "open sans"; 
  display: inline-block;
}
.clickMe:hover{
  background-color: transparent;
  color: #FFFFFF;
}
.linkto{
  text-decoration: none;
  color: #000000;
}

.linkto:hover{
  color: #000000;
}

.aero {
  width: 1.65rem;
  height: 1.1975rem;
}

 .aero2{
  display: none;
  position: absolute;
  width: 1.77125rem;
  height: 1.1975rem;
}

.clickMe:hover .aero2{
  display:inline;
 

}



@media only screen and (max-width: 576px){

    .heading{
        font-size: 1rem!important;
    }



    .firstLine{
        font-size: 0.995rem!important;
        font-weight: 700!important;

    }

    .paragraph{
        font-size: 0.663125rem!important;

    }

    .clickMe{
        font-size: 0.663125rem!important;
        border-radius: 0.331675rem!important;
    }

    .aero{
        width:  0.94rem!important;
        height: 0.634375rem!important;
    }

}

@media only screen and (max-width:768px){
  .career-hero {
    min-height: 50vh;
}

.career-hero h1{
    font-size: calc(0.8rem + 2.3vw);
    line-height: 3rem;
    word-spacing: 0.4rem;
}

}

@media (min-width:768px) and (max-width: 1050px){
    .heading{
        font-size: 2rem!important;
    }

    .firstLine{
        font-size: 1.5rem!important;

    } 

    .paragraph{
        font-size: 0.9rem!important;

    }

    .clickMe{
        font-size: 0.9rem!important;
        border-radius: 0.331675rem!important;
    }

    .aero{
        width:  1.1rem!important;
        height: 0.7rem!important;
    }
}

@media only screen and (max-width: 576px) {

  .career-hero h1{
    font-size: calc(0.8rem + 2.3vw);
    line-height: 3rem;
    word-spacing: 0rem;
  }
}



