.terms-hero {
    background-image: url("../../../public/assets/termsofuseimages/write.png");
    background-repeat: no-repeat;
    min-height: 90vh;
    position: relative;
    background-size: cover;
    background-position: bottom;
    overflow: hidden;
}

.terms-hero h1{
    font-weight: 600;
    font-size: 3rem;
}

.color-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
}


.allhead{
    font-weight: 700; 
}

.termsvc{
    width: 1.25rem;
}

.allfont{
    padding-top: 0.5rem;
}

@media only screen and (max-width: 768px) {
    .terms-hero {
        min-height: 50vh;
    }

    .terms-hero h1{
        font-size: calc(0.8rem + 2.3vw);
    }

    .allfont{
        padding-top: 0.8rem !important;
    }
}