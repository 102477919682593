@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800&display=swap');

body {
    overflow-x: hidden;
 }

 .newbg{
    background-color: #FFDAA5;
 }

 .jobline{
    width: 88.8%;
 }

.footnewbg{
    background: #FFFFFF;
}

.footlabel {
    font-weight: 500;
    font-family: 'Inter', sans-serif!important;
    margin-left: -10px;
}



.uselinks a {
    text-decoration: none!important;
    color: black!important;
}

.footlink, .footmenu {
    margin-left: 1.7rem!important;
}

.humanssofttech {
    font-size: 1.2rem!important;
    word-spacing: 2px!important;
    font-family: 'Inter', sans-serif!important;
}

.office {
    line-height: 1.3rem;
    font-weight: 400;
    font-family: 'Inter', sans-serif!important;
}

.test-div h1{
    letter-spacing: 0.1rem;
    font-weight: 400;
    font-family: 'Inter', sans-serif!important;
}

.foottouch {
    font-size: 50px;
    letter-spacing: 0.1rem;
    font-weight: 700;
    color:#B4531C;
    font-family: 'Inter', sans-serif!important;
}

.form-check-labelnew {
    font-size: 13.7px!important;
    font-family: 'Inter', sans-serif!important;
}

.diff-one{
    display: none;

}

.uploadfile{
    width: 270px;
}



/* FORM INPUT BOX GLOW */
.form-control:focus {
    border-color: #AD3E00!important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.050), 0 1px 8px rgb(237, 180, 148);
  }

  .spannew{

    font-weight: 500;
    font-family: 'Inter', sans-serif!important;
    font-size: 1rem!important;
  }

.formnew{

    padding-top: 0.2rem;
    font-size: 1rem;
    border: 1px solid #AD3E00!important;
    border-radius: 5px;
    height: 2rem; 
    color: gray;
    background-color: #FFFFFF;
    font-family: 'Inter', sans-serif!important;
    
    
}



.formnew:hover{
    cursor: pointer;
}

.footformnew {
    background-color: #F5F9FF;
    overflow: hidden;
    padding: 3rem 3rem;
    border:1px solid black;
    border-radius: 10px;
    height: auto;
    
    
}

.footformnew h3 {
    margin-top: -20px!important;
    margin-bottom: 2rem!important;
    font-weight: 400;
    text-align: center;
    font-family: 'Inter', sans-serif!important;
}

textarea, input {
   
    box-sizing: border-box!important;
    border: 1px solid #AD3E00!important;
    -webkit-transition: 0.5s!important;
    transition: 0.5s!important;
    outline: none!important;
  }
  
input:focus {
    border: 1px solid #AD3E00!important;
}

.careerfootbutton {
    font-size: 20px!important;
    background-color: white!important;
    border: 1px solid #B4531C;
    border-radius: 10px;
    font-weight: 500;
    width: 8rem;
    font-family: 'Inter', sans-serif!important;
}

.newcheckbtn{
    margin-left: 0.8rem;
}

.careerfootbutton:hover {
    color: #FFFFFF;
    background-color: #FF7D33!important;
}

hr{
    border: none;
    height: 1.5px;
    background: #363636!important;
}

.jugaad {
    display: none!important;
  }

@media only screen and (max-width: 992px) {
    .test-div{
        display: block!important;
      
        margin-bottom: 2rem!important;
    }


    .footform-mobile{
        justify-content: space-around!important;
        
    }
}

@media only screen and (max-width: 768px) {
 
    .footformnew h3 {
        margin-top: -30px!important;
        margin-bottom: 0rem!important;
        font-weight: 400;
        text-align: center;
    }
  
    .form-group, .form-check,.subbtn{
       
        margin-top: 2rem!important;
    }

    .test-div{
        display: block!important;
    }


    .footform-mobile{
        justify-content: space-around!important;
      
    }

    

    .copyright{
        font-size: 0.9rem!important;
    }
  }
  
  @media only screen and (max-width: 767px) {
  
    .footformnew {
        background-color: #F5F9FF;
        overflow: hidden;
        padding: 4rem 1rem;
        /* padding: 20px 20px; */
        border:1px solid black;
        border-radius: 10px;
        height: auto;
    }

    .footformnew h3{
        font-size: 1rem!important;
    }

    .formnew{
        padding: 0.2rem 0.7rem!important;
    }

    .newcheckbtn{
        margin-left: 2.2rem;
    }

    .form-row{
        margin-top: 2rem!important;
    }
    
    .form-control{
        padding: 0.2rem 0.7rem!important;
    }

    .form-group, .form-check,.subbtn{
        margin-top: 0rem!important;
    }

    .subbtn{
        margin-bottom: -1rem!important;
    }
  }

 

  @media only screen and (max-width: 950px) {
    .terms{
        display: none!important;
    }

    .jugaad{
        display: block!important;
    }
  }

  @media only screen and (max-width: 679px) {
    .newcheckbtn{
        margin-left: 2rem;
    }
    
  }


  /* ALIGN FOOTER IMAGES GRID */
@media only screen and (max-width: 700px) {
    
    .grid-container {
        display: grid!important;
        grid-template-columns: auto auto auto auto auto !important;
        overflow: hidden!important;
    }

    .footlink img{
        width: 30px!important;
    }
    
    

    .img-container{
        display: grid!important;
        grid-template-columns: auto auto auto auto!important;
        overflow: hidden!important;
    }

}

@media only screen and (max-width: 580px) {



    

    .kartik2, .stay-tuned{
        font-size: 0.8rem!important;
    }

    .newcheckbtn{
        margin-left: 1.2rem;
    }

    .footlink {
        margin-left: 1rem!important;
    }

    .footmenu {
        margin-left: 1rem!important;
    }

    .jugaad{
        font-size: 0.9rem!important;
    }

    .copyright{
        font-weight: 400!important;
        font-size: 0.8rem!important;
    }
    
    .email img{
        width: 30px!important;
    }

    .skype img{
        width: 20px!important;
    }
    .mobile-form {
        font-size: 0.9rem!important;
    }

    .grid-container {
       display: grid!important;
       grid-template-columns: auto auto auto!important;
       overflow: hidden!important;
   }
    .footlink img{
        width: 25px!important;
    }

    .img-container{
        display: grid!important;
        grid-template-columns: auto auto auto auto!important;
        overflow: hidden!important;
    }
}

@media only screen and (max-width: 450px) {
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      
        font-size: 0.7rem;
    }
    .formnew{
        font-size: 0.7rem;
        padding: 0.5rem 0.7rem!important;
    }
}