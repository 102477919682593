.project-hero {
  background-image: url("../../../public/assets/projectimage/projectHero.png");
  background-repeat: no-repeat;
  min-height: 90vh;
  position: relative;
  background-size: cover;
  background-position: bottom;
  overflow: hidden;
}

.project-hero h1 {
  font-weight: 600;
  font-size: 3rem;
  line-height: 4.5rem;
  word-spacing: 0.5rem;
}
 
.color-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}

.cowboy {
  color: #000000;
  font-size: 4rem;
  font-weight: 800;
  padding-top: 6rem;
  font-family: 'Open Sans', sans-serif;
}

.bgBorderRadius{
  border-radius: 50px!important;
}


.vc{
  width: 2rem;
  margin-left: 1.5rem;

}
.star{
  width: 10rem;
}
.vc1{
  width: 3rem;

}
.text2 {
  font-size: 1.7rem;
  font-family: "Open Sans";
  letter-spacing: 0.01em;
  margin-top: 6rem;

}

.text3 {
  font-size: 1.7rem;
  font-family: "Open Sans";
  letter-spacing: 0.01em;
  font-weight: 800;
}

.text4 {
  font-size: 1.7rem;
  font-family: "Open Sans";
  letter-spacing: 0.01em;
}

.text5 {
  font-size: 1.7rem;
  font-family: "Open Sans";
  letter-spacing: 0.01em;
  font-weight: 800;
}

@media only screen and (max-width: 768px) {
  .project-hero {
      min-height: 50vh;
  }

  .project-hero h1{
      font-size: calc(0.8rem + 2.3vw);
      line-height: 3rem;
      word-spacing: 0.4rem;

  }

  .cowboy{
    font-size: 2rem!important;
    padding-top: 2rem;
  }
  .text4 {
    margin-left: 0.5rem !important;
    font-size: 1.2rem;

  }
  .text3{
    font-size: 1.2rem;
  }

  .text5 {
    /* margin-top: -0.4rem !important; */
    font-size: 1.2rem;
    margin-left: 0.5rem;
  }
  .text2 {
    margin-top: 0rem !important;
    font-size: 1.2rem;
 }

 .image1{
  width: 15rem;
  height: 20rem;
 }
 .vc{
  width: 1.2rem;
  margin-left: 0rem!important;

 }
 .vc1{
  width: 1.5rem!important;

 }
 .star{
  width: 8rem;
  margin-left: -0.2rem!important;

 }
}

@media only screen and (max-width: 576px) {

  .project-hero h1{
        font-size: calc(0.8rem + 2.3vw);
        line-height: 3rem;
        word-spacing: 0rem;
    }

  

  .bgBorderRadius{
    border-radius: 40px!important;
  }
 

  .vc1{
    width: 1.200625rem!important;
    
  }

  .vc{
    width: 1rem;
    margin-left: 0rem!important;
    margin-top: -2rem!important;
  }
 

  .cowboy {
   
    font-size: 1.25em !important;
    padding-top: 2rem;
  }

  .image1 {
    width: 9.875rem;
    height: 14.9375rem;
  }

  .text2 {
    font-size: 0.75rem !important;
    margin-top: 1rem;
  }

  .text3 {
    font-size: 0.75rem !important;
  }

  .star{
    width: 5.52875rem;
    margin-top: -1.5rem!important;
    margin-left: -0.1rem!important;
  }

  .text4 {
    font-size: 0.75rem !important;
    margin-top: -0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .text5 {
    font-size: 0.75rem !important;
    margin-top: 0.3rem!important;
    margin-left: 0.2rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  
  .bgColor {
    margin-left: 2rem !important;
  } 

  .image1 {
    margin-top: 0rem !important;
    width: 15rem;
    height: 20rem;
  }

  .cowboy{
    font-size: 2.5rem;
    padding-top: 2rem!important;
  }

  .text2 {
    margin-top: 0rem !important;
    font-size: 1.5rem;
  }

  .text3 {
    font-size: 1.5rem;
  }

  .text4 {
    margin-left: 1.5rem !important;
    font-size: 1.5rem;
  }

  .text5 {
    margin-top: -0.2rem;
    margin-left: 0.5rem!important;
    font-size: 1.5rem;
  }

  .vc {
    margin-left: 0rem !important;
  }

}
