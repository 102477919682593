.sticky-top {
    background-color: #ffff;
}


.nav-link {
    font-size: 1.25rem!important;
    font-weight: 450!important;
    margin-left: 2rem!important;
}

.nav-link:hover{
    color: #C28A3F!important;
}

 .navbar-toggler {
    width: 20px;
    height: 20px;
    position: relative;
    transition: .5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: 0;
}
 
.navbar-toggler span {
    margin: 0;
    padding: 0;
}

.toggler-icon {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: black;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.middle-bar {
    margin-top: 0px;
}

/* when navigation is clicked */

.navbar-toggler .top-bar {
    margin-top: 0px;
    transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
    opacity: 0;
    filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
    margin-top: 0px;
    transform: rotate(-135deg);
}

/* State when the navbar is collapsed */

.navbar-toggler.collapsed .top-bar {
    margin-top: -20px;
    transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
    opacity: 1;
    filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
    margin-top: 20px;
    transform: rotate(0deg);
}

.for-mob{
    display: none!important;
}

.for-desktop{
    display:inline-flex!important;
}


/* Color of 3 lines */

.navbar-toggler.collapsed .toggler-icon {
    background: black;
}

@media only screen and (max-width: 768px) {
    ul {
        height: 82vh!important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 991px){
    ul {
        height: 85vh;
    }
}

@media only screen and (max-width: 991px) {
    ul {
        /* height: 80vh; */
    }
    .nav-link {
        /* height: 100vh!important; */
        font-size: 40px!important;
        font-weight: 700!important;
        line-height: 10vh!important;
    }

    .for-mob{
        display: block;
    }

    .for-desktop{
        display:none;
    }

  }

  @media only screen and (max-width: 450px) {
    .nav-link {
        font-size: 30px!important;
        font-weight: 650!important;
        line-height: 10vh!important;
    }
  }