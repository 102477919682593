.main-hero {
    background-image: url("../../public/assets/homeimages/Hero1.png");
    background-repeat: no-repeat;
    min-height: 90vh;
    position: relative;
    background-size: cover;
    background-position: bottom;
    overflow: hidden;
}

.main-hero h1{
    font-weight: 600;
    font-size: 3rem;
    line-height: 4.5rem;
    word-spacing: 0.5rem;
}

.color-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
}

.handshake {
    border-radius: 2rem;
    box-shadow: 1px 1px 5px 5px rgba(140, 134, 134, 0.25);
}

.teamimg{
    border-radius: 20px;
    
}

.dash{
    font-size: 1rem;
    font-weight: 800;
    color: #B4531C;
}

.handshaketext{
    font-size: 1.2rem;
}

.gapping {
    margin-bottom: 2rem;
}
.abouttext{
    font-size: 1.2rem;
    line-height: 2.2rem;
}

.whywedo {
    height: auto;
}

.custombg {
    height: 700px;
    background-color: #FFDAA5D6;
}

.ourwork {
    height: auto;
}

.servhead{
    font-weight: 600;
}

.techwework{
    height: auto;
}

.abouthumans{
    height: auto;
}

.headcss {
    font-size: 1.8rem;
    font-weight: 600;
}

.techmenu {
    width: 100%;
    font-size: 1.4rem;
    overflow: auto;
    color: #C28A3F;
    display: block;
    white-space: nowrap;
}

.techmenu button {
    display: inline-block;
    color: black;
    text-decoration: none;
    border: none;
    font-size: 1.3rem;
    text-align: center;
    padding: 14px;
    text-decoration: none;
}



.imgs img {
    max-width: 90px;
}


/* Scrollbar Styling */

.techmenu::-webkit-scrollbar
{
    height: 6px;
}

.techmenu::-webkit-scrollbar-track
{
	
	background-color: #8c8c8c;
    border: 2px solid white;
    border-radius: 0.1rem;
  
}

.techmenu::-webkit-scrollbar-thumb
{
	background-color: #000000;
	border: 0px solid #555555;
    border-radius: 10px;
   
}

.currentjob{
    height: auto;
    background-color: #FFDAA5D6; 
}

.workbox{
    width: 500px;
    height: auto;
    background-color: #FFD1E9;
    border-radius: 20px;
}

.figure-caption{
    color: black;
    font-size: 15px;
    font-weight: 400;
}

/* BUTTONS CSS */

.explore {
    background-image: linear-gradient(to right, #185A9D 0%, #43CEA2  51%, #185A9D  100%);
    padding: 15px 40px;
    cursor: pointer!important;
    font-weight: 700;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;       
    border: none;
    display: block;
}

.explore:hover {
    background-position: right center; /*change the direction of the change here */
    color: #fff;
    text-decoration: none;
    cursor: pointer!important;
}

.mobexplore {
    background-image: linear-gradient(to right, #185A9D 0%, #43CEA2  51%, #185A9D  100%);
    padding: 10px 15px;
    cursor: pointer!important;
    font-weight: 700;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;       
    border: none;
    display: block;
}

.mobexplore:hover {
    background-position: right center; /*change the direction of the change here */
    color: #fff;
    text-decoration: none;
    cursor: pointer!important;
}


.projbutton{
    border: 2px solid transparent;
    border-radius: 10px;
    color: white;
    width: 33%;
}

.projbutton:hover{
    background-color: transparent!important;
    border-color: rgb(0, 0, 0);
    color: black;
    border: 1.5px solid black;
}


.viewtech {
    background-color: white;
    color: black;
    border: 2px solid #FF7D33;
}

.viewtech:hover {
    background-color: #FF7D33;
    color: white;
    border: none;
}

.viewprojbut{
    display: block;
}

.viewjobbut{
    display: block;
}

.viewaboutbut{
    display: block;
}

.viewtechbut{
    display: block;
}

.viewproj {
    font-size: 1rem;
    background-color: transparent;
    color: black;
    border: 3px solid #FF7D33;
    border-radius: 10px;
}

.viewproj:hover {
    background-color: #FF7D33;
    color: white;
    border-color: transparent;
}


.viewabout {
    background-color: white;
    color: black;
    border: 2px solid #FF7D33;
}

.viewabout:hover {
    background-color: #FF7D33;
    color: white;
    border: none;
}

.viewjob {
    font-size: 1rem;
    background-color: transparent;
    color: black;
    border: 3px solid #FF7D33;
    border-radius: 10px;
}

.viewjob:hover, .viewjob:active{
    background-color: #FF7D33!important;
    color: white;
    border-color: transparent!important;
}

.btn:focus,.btn:active {
    outline: none !important;
    box-shadow: none;
 }

.remove-default-bg:hover, .remove-default-bg:active{
    background-color: #FF7D33!important;
    color: white;
    border-color: transparent!important;
}


/* BUTTONS CSS ENDS HERE */

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-bar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  /* OVERLAP OF BUTTONS WITH LISTSBOX CODE IS BELOW */
  /* position: relative;
  top: 10px; */
}

.imgs{
    height: auto;
}


.hyphen{
    color: #B4531C;
    font-weight: bold;
    font-size: 1rem;
 
}


.hyphen-lists {
    padding-left: 0.3rem ;
}

.listscol{
    padding-top: 1rem;
    column-gap: 0rem;
    font-size: 1.3rem;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    padding-left: 0;
    max-height: 75%;
    overflow: hidden;
}



.listbutton{
    border :1.4px solid #893169;
    padding: 15px 30px;
    width: 75%;
    text-align: start;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: black;         
    background-color: white;
    border-radius: 10px;
    display: inline-block;
    position: relative;
}

.listbutton:hover{
    text-decoration: none;
    background-image: linear-gradient(to right, #1D2671 0%, #C33764  51%, #1D2671  100%);
     color: white;
     background-position: right center; /* change the direction of the change here */
}

.btn-grad {
    background-image: linear-gradient(to right, #1D2671 0%, #C33764  51%, #1D2671  100%);
    padding: 15px 30px;
    width: 75%;
    text-align: start;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;         
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
}

.btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}
 
.contentblock{
    border: 1px solid #B4531C;
    border-radius: 5px;

    padding-top: 2rem;
    padding-left: 6rem;

    width: 200%;
    min-height: 460px;
    max-height: 575px;

    margin-left: -200px!important;
    background-color: #fff;

}

.techbg {
    height: 200px;
}

.jobopencol{
    margin-top: 2rem;
    columns: 4;
    -webkit-columns: 4;
    -moz-columns: 4;
}

.jobslists{
    font-size: 1.1rem;
    font-weight: 600;
    padding-top: 1.5rem;
}

.jobslists a{
    color: black;
    text-decoration: none;
}

.btn-group button {
    background-color: transparent;
    border: none!important;
    border-bottom: 1px solid black;
    color: black; /* White text */
    padding-left: 30px; 
    cursor: pointer; /* Pointer/hand icon */
    float: left; /* Float the buttons side by side */
  }
  
  /* Clear floats (clearfix hack) */
  .btn-group:after {
    content: "";
    clear: both;
    display: table;
  }
  
  .btn-group button:not(:last-child) {
    border-right: none; /* Prevent double borders */
  }
  
  /* Add a background color on hover */
  .btn-group button:hover {
    font-weight: 600;
  }

.slider-arrow-home{
    height: 4rem;
    margin-top: -2.2rem;
    margin-right: 2.9rem;
    
}


/* Scrollbar Styling */
.scrollerproj::-webkit-scrollbar-track
  {
      /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
      background-color: #8c8c8c;
    border: 1px solid black;
    border-top: 3px solid #ffe0b3;
    border-bottom: 3px solid #ffe0b3;
    border-right: 3px solid #ffe0b3;
    border-left: 3px solid #ffe0b3;
  margin-left: 3rem;
  margin-right: 3rem;
  border-radius: 0.1rem;
  }

.scrollerproj::-webkit-scrollbar
{
    height: 7px;
}

.scrollerproj::-webkit-scrollbar-track-piece{
    height: 2px!important;
}

.scrollerproj::-webkit-scrollbar-thumb
{
	background-color: #000000;
	border: 0px solid #555555;
    width:2px !important;
    height: 4px !important;
    border-radius: 3rem;
}

.viewprojjugaad{
    display: none;
}

.viewaboutjugaad{
    display: none;
}

.viewjobjugaad{
    display: none;
}

.viewtechjugaad{
    display: none;
}

/* TABLET MEDIA QUERY */

@media only screen and (max-width: 768px) {
    .main-hero {
        min-height: 50vh;
    }
    
    .viewtech{
        display: hidden!important;
    }

    .main-hero h1{
        font-size: calc(0.8rem + 2.3vw);
        line-height: 3rem;
        word-spacing: 0.4rem;
    } 

    .slider-arrow-home{
        height: 4rem;
        width: 2rem;
        margin-top: -2rem;
        
    }
    
    .workbox{
        width: 450px;
        height: auto;
        background-color: #FFD1E9;
        border-radius: 20px;
    }

    .custombg {
        height: auto;
        background-color: #FFDAA5D6;
    }

    .headcss {
        font-size: 1.5rem;
    }

    .handshaketext{
        font-size: 1rem;
    }

    .whywedo {
        height: auto;
    }

    .projbutton{
        width: 35%;
    }

    .abouttext{
        font-size: 1.1rem;
        line-height: 2.0rem;
    }

    .viewtechbut{
        display: none;
    }

    .viewtechjugaad{
        display: block;
    }

    .jobopencol{
        margin-top: 2rem;
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }

    .jobslists{
        font-size: 1.1rem;
        padding-top: 1.2rem;
    }

    .explore {
        font-size: 1.3rem;
        padding: 12px 40px;
    }
    
    .listbutton{
        padding: 10px 20px;
        width: 70%;
        font-size: 0.9rem;
    }

    .listscol{
        padding-top: 1rem;
        column-gap: 0rem;
        font-size: 1.2rem;
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
        padding-left: 0;
        max-height: 65%;
        overflow: hidden;
    }

    .contentblock{
        margin-left: -150px!important;
    }

    .imgs img {
        max-width: 80px;
    }

    .figure{
        display: none!important;
    }
    .iconsmob{
        display: block!important;
    }

    .oaeblock{
        display: none;
    }

    .oaemobblock {
        display: block!important;
    }

  }

  .iconsmob{
    display: none;
  }

  .oaemobblock{
    display: none;
  }

/* SPECIFICALLY FOR OAE BLOCK UNTIL TABLET AND DESKTOP */
@media only screen and (max-width: 950px) and (min-width: 769px){
    
    .explore {
        font-size: 1.1rem;
        padding: 15px 30px;
    }
    
    .listbutton{
        padding: 15px 20px;
        width: 70%!important;
        font-size: 1rem;
    }

    .listscol{
        padding-top: 1rem;
        column-gap: 0rem;
        font-size: 1.2rem;
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        padding-left: 0;
        max-height: 65%;
        overflow: hidden;
    }

    .contentblock{
        min-height: 360px;
        max-height: 500px;
        margin-left: -175px!important;


    }

    .custombg{
        height: 650px;
    }

    .scroll-bar{
        max-height: 20%!important;
    }
}

/* MOBILE MEDIA QUERY */

  @media only screen and (max-width: 576px) {

    .main-hero h1{
        font-size: calc(0.8rem + 2.3vw);
        line-height: 3rem;
        word-spacing: 0rem;
    } 

    .headcss {
        font-size: 1.2rem;
    }

    .handshaketext{
        font-size: 0.8rem;
    }

    .slider-arrow-home{
        display: none;
    }

    .whywedo{
        height: auto;
    }

    .workbox{
        width: 400px;
        height: auto;
        background-color: #FFD1E9;
        border-radius: 20px;
    }

    .abouttext{
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .jobslists{
        font-size: 1rem;
        padding-top: 1rem;
    }

    .explore {
        font-size: 1.1rem;
        padding: 11px 30px;
    }

    .imgs img {
        max-width: 60px;
    }

    .moblist{
        width: 30px;
    }

    .accordion .item .item-header h2 button.btn.btn-link {
        font-size: 15px!important;
    }

    .accordion .item .item-header h2 .arrow{
        width: 30px!important;
        height: 30px!important;
    }
}



@media only screen and (max-width: 425px) {
    
    .viewprojbut{
        display: none;
    }

    .viewprojjugaad{
        display: block;
    }

    .workbox{
        width: 300px;
        height: auto;
        background-color: #FFD1E9;
        border-radius: 20px;
    }

    .workbox img {
        width: 200px;
    }

    .projbutton{
        width: 45%;
    }

    .abouttext{
        font-size: 0.9rem;
        line-height: 1.5rem;
    }

    .jobslists{
        font-size: 0.9rem;
        padding-top: 0.9rem;
    }

    .imgs img {
        max-width: 50px;
    }

    .oaemobblock{
        margin-top: -1rem;
    }

    /* .accordion .item .item-header h2 button.btn.btn-link {
        font-size: 13px!important;
    } */

    .accordion .item .item-header h2 .arrow{
        width: 28px!important;
        height: 28px!important;
    }

    .scrollerproj::-webkit-scrollbar-track{
        margin-left: 1.5rem;
        margin-right: 1.5rem;
  }
}

/* SPECIFICALLY FOR SECTION {ABOUT HST} AND {JOB OPENINGS} HEADING */
@media only screen and (max-width: 484px) {

    .viewaboutbut{
        display: none;
    }

    .viewaboutjugaad{
        display: block;
    }

    .viewjobbut{
        display: none;
    }

    .viewjobjugaad{
        display: block;
    }

    .explore{
        display: none;
    }

    .human{
        width: 100%!important;
    }

    .currentjob{
        width: 100%!important;
    }
    
    .headoae{
        width: 100%!important;
    }
}


@media only screen and (max-width: 1000px) and (min-width: 768px) {
    .jobopencol{
        margin-top: 2rem;
        columns: 3;
        -webkit-columns: 3;
        -moz-columns: 3 ;
    }
}


  .accordion{
    margin: 40px 0;
  }
  .accordion .item {
      border: none;
      margin-bottom: 20px;
      background: none;
  }
  
  .t-p{
    background-color: white;
    color: black;
    padding: 15px 0px 15px 15px;
    border: 1px solid black;
    border-width: 0.1rem;
    margin-top: -0.5rem;
  }

  .accordion .item .item-header h2 button.btn.btn-link {
      background: white;
      color: black;
      border-width: 0.1rem;
      border-color: black;
      border-radius: 10px;
      font-size: 20px;
      font-weight: 400;
      line-height: 2.5;
      text-decoration: none;
  }

  .accordion .item .item-header {
      border-bottom: none;
      background: transparent;
  }
  
  .accordion .item .item-header h2 button {
      color: white;
      font-size: 20px;
      padding: 15px;
      display: block;
      width: 100%;
      text-align: left;
  }
  
  .accordion .item .item-header h2 .arrow {
      float: right;
      /* background-color: black; */
      width: 40px;
      height: 40px;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
  }
  
  button.btn.btn-link.collapsed img {
      transform: rotate(0deg);
  }
  
  button.btn.btn-link img {
      transition: 0.5s;
  }
  