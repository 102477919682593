.main-hero-about{
    background-image: url("../../public/assets/aboutimages/about-hero.png");
    background-repeat: no-repeat;
    min-height: 90vh;
    position: relative;
    background-size: cover;
    background-position: right;
    overflow: hidden;
  }
  
.main-hero-about h1{
  font-weight: 600;
  font-size: 3rem;
  line-height: 4.5rem;
  word-spacing: 0.5rem;
  }

  .color-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
  }

.about-heading{
  font-size: 3rem;
  font-weight: 600;
}

.boxes{
  padding: 5rem 0;
  /* height:20rem; */
  font-size: 1.5rem;
  border-radius:30px;
  color:white;
}

.quote-box{
  border-image: linear-gradient(#1F2671B2,#01C9BDB2);
  border-width: 4px;
  border-style: solid;
  min-height: 100%;
  /* margin: 0 7rem !important; */
}

.quote{
  position: relative;
  font-size: 1.4rem;
  font-weight: 500;  
}

.quote-name{
  font-size: 1.75rem;
  font-weight: 700;
  
}
.quote-position{
  font-size: 1.25rem;
  font-weight: 500;
}
.text-head{
  font-size: 1.5rem;
  font-weight: 600;
}
.about-text-content{
  font-size: 1.2rem;
}

.about-hyphen{
  width: 20%;
}

.right-quote{
  position: relative;
  top: -1rem;
  width:3rem;
}

.lower-quote{
  position: relative;
  bottom: -0.5rem;
  width:3rem;
}

.box-around-1{
  margin-top: -1rem;
  margin-left: 1rem;
} 

.box-around-2{
  margin-bottom: 4rem !important;
  margin-right: 10rem !important;
}

.team-image{
  width:90%;
  border-radius: 40px!important;
}

.expertise-image{
  height: 45%;
}
 
.expertise-text{
  font-size: 1.1rem;
}

.expertise-box{
  border-radius: 35px!important;
}

.expertise-box:hover{
  cursor: pointer;
}
.about-block-text{
  font-size: 1.5rem;
}

/* ----------------------- Hyphen Styling -------------------------- */

@media only screen and (min-width: 450px) and (max-width: 576px){
  

  .about-block-small-screen-text{
    font-size: 1.15rem!important;
  }


}


@media only screen and (max-width: 1200px) and (min-width: 769px){
  .about-hyphen{
    width: 14%;
    margin-right: -1rem;
  }
}
/* ------------------------ Hyphen Styling Over ------------------------- */

@media only screen and (min-width: 320px) and (max-width: 420px){
  .expertise-image{
    width: 35%!important;
    height: 35%;
    }
  .expertise-text{
      font-size: 0.5rem!important;
     
    }
  .expertise-box{
      border-radius: 15px!important;
      width: 31%!important;
  }
  .mobile-about-block{
    height:180px!important;
  }
}

@media only screen and (min-width: 420px) and (max-width:576px){
  .mobile-about-block{
    height:155px!important;
  }
}

@media only screen and (max-width:576px){

  .main-hero-about h1{
      font-size: calc(0.8rem + 2.3vw);
      line-height: 3rem;
      word-spacing: 0rem; 
  }

  .about-heading{
    font-size: 1.5rem!important;
  }

  .about-hyphen{
    width: 14%;
    margin-right: 1.5rem;
  }

  .team-image{
    border-radius: 20px!important;
  }

  .boxes{
    padding: 1rem 0;
    font-size: 0.75rem;
    border-radius:15px;
    color:white;
  }

  .quote-box{
    margin: 0 0rem!important;
  }

  .quote{
    font-size: 1.2rem;
    font-weight:500;
  }
  
  .quote-name{
    font-size: 1.15rem;
  }
  .quote-position{
    font-size: 1rem;
  }
  .right-quote{
    top: -0.5rem;
    width: 1.5rem!important;
  }
  .lower-quote{
    bottom: -0.25rem;
    width:1.5rem;
  }
  .expertise-image{
  width: 35%;
  }
  .expertise-text{
    font-size: 0.7rem!important;
  }

  .expertise-box{
    border-radius: 20px!important;
    width: 31%!important;
  }

  .about-hyphen{
    font-size: 1.5rem!important;
  }

  .text-head{
    font-size: 1rem!important;
  }

  .about-text-content{
    font-size: 0.85rem!important;
  }

  .about-block-small-screen-text{
    font-size: 0.8rem!important;
  }

   
}




@media only screen and (max-width: 768px) {
  .main-hero-about {
        min-height: 50vh;
        background-position: right;
  }
  .main-hero-about h1{
      font-size: calc(0.8rem + 2.3vw);
      line-height: 3rem;
      word-spacing: 0.4rem;
  } 
    .about-heading{
      font-size: 2rem;
    }
    .team-image{
      border-radius: 5rem;
    }
    .right-quote{
      top: -0.5rem;
      width: 2.75rem!important;
    }
    .lower-quote{
      bottom: -0.25rem;
      width:2.75rem;
    }
    .quote-box{
      margin: 0 0rem!important;
    }
    .text-head{
      font-size: 1.5rem;
    }
    .about-text-content{
      font-size: 1.15rem;
    }
    .box-content{
      height: 100%!important;
      padding: 10rem 0;
    }
    .expertise-image{
      width: 35%;
      height: 35%;
      }
    .expertise-text{
        font-size: 0.9rem;
        /* padding-bottom: 15px; */
      }
    .expertise-box{
        border-radius: 15px;
        width: 32%;
    }
    .mobile-about-block{
      height:275px;
    }
    
    .twoquote{
      /* display: block!important; */
      justify-content:flex-start!important;
      overflow-x: scroll!important;
    }

    /* Scrollbar Styling */
.twoquote::-webkit-scrollbar-track
{
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
    background-color: #8c8c8c;
  border: 1px solid black;
  border-top: 3px solid #FFDAA5;
  border-bottom: 3px solid #FFDAA5;
  border-right: 3px solid #FFDAA5;
  border-left: 3px solid #FFDAA5;
margin-left: 2rem;
margin-right: 2rem;
border-radius: 0.1rem;
}

.tempdiv{
  padding-bottom: 2rem!important;
  background-color: #FFDAA5 ;
}

.twoquote::-webkit-scrollbar
{
  height: 7px;
}

.twoquote::-webkit-scrollbar-track-piece{
  height: 2px!important;
}

.twoquote::-webkit-scrollbar-thumb
{
background-color: #000000;
border: 0px solid #555555;
  width:2px !important;
  height: 4px !important;
  border-radius: 3rem;
  
}


    .q1,.q2{
      width: 100%!important;
      
    }
    
}

@media only screen and (min-width: 768px) and (max-width: 1200px){
  .about-block-text{
    font-size: 1.35rem!important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px){
  .expertise-box{
    border-radius: 30px!important;
    width: 32%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1900px){
  .expertise-box{
    border-radius: 30px;
    width: 18.5%;
    padding-bottom:1rem ;
  }
}


@media only screen and (max-width:1200px){
  .right-quote{
    top: -0.5rem;
    width: 2.5rem!important;
  }
  .lower-quote{
    bottom: -0.25rem;
    width:2.5rem;
  }
  .quote-box{
    margin: 0 0rem!important;
  }
  .text-head{
    font-size: 1.75rem;
  }
  .about-text-content{
    font-size: 1.25rem;
  } 
}

