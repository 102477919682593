.error-hero {
    background-image: url("../../public/assets/error404images/caution.jpg");
    background-repeat: no-repeat;
    min-height: 90vh;
    position: relative;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.error-hero h1{
    color:black;
    font-weight: 600;
    font-size: 3rem;
    letter-spacing: 0.2rem;
}

.error-text h2{
    font-weight: 500;
    font-size: 2.5rem;
    letter-spacing: 0.2rem;
}

.errorcolor-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
}

@media only screen and (min-width:1200px) and (max-width: 2000px){
    .error-hero {
        min-height: 90vh;
    }
}


@media only screen and (max-width: 768px) {
    .error-hero {
        min-height: 50vh;
    }

    .error-hero h1{
        font-size: calc(0.3rem + 3vw);
    }

    .error-text h2{
        font-size: calc(1.5rem + 1vw);
    } 
}

@media only screen and (max-width: 576px){
    .error-hero {
        min-height: 50vh;
    }
}

@media only screen and (max-width: 425px){

    .error-hero {
        min-height: 50vh;
    }

    .error-text h2{
        font-size: calc(1rem + 2vw);
    } 
}