.main-hero-technology{
    background-image: url("../../public/assets/technologiesimages/technology-hero.png");
    background-repeat: no-repeat;
    min-height: 90vh;
    position: relative;
    background-size: cover;
    background-position: left;
    overflow: hidden;
  }
  
.main-hero-technology h1{
  font-weight: 600;
  font-size: 3rem;
  line-height: 4.5rem;
  word-spacing: 0.5rem;
}

  .content-container{
    box-shadow: 5px 5px 5px 4px rgba(0, 0, 0, 0.1);
  }
  .color-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
.content{
    margin-bottom: 5rem;
    font-size: 1.5rem;
}

.tech-scroller {
    scrollbar-color: black #8c8c8c;
    -ms-overflow-style:none ;
}


.tech-scroller-white::-webkit-scrollbar-track
{
  border: 3px solid white !important;
}

.tech-scroller::-webkit-scrollbar-track
  {
      background-color: #8c8c8c;
      border: 3px solid #ffe0b3;
      border-radius: 0.1rem;
      margin-left:3rem;
      margin-right:3rem;
   
  
  }
  
  .tech-scroller::-webkit-scrollbar
  {
      height: 7px;

  }
  
  
  .tech-scroller::-webkit-scrollbar-thumb
  {
      background-color: #000000;
      height: 4px !important;
      border-radius: 3rem;
}
  
  .img-scroller{
    width: 7rem;
  }
  
  .color-round{
    border-radius: 1.85rem;
  }
  
  .slider-arrow{
    height: 4rem;
    width: 2rem;
    margin-top: -2.2rem;
    margin-right: -0.1rem;
  
  }
  
  
  /* -------------- Media Queries ------------------- */
  /*----------------------- Columns Mid Points ---------------- */
  @media only screen and (min-width: 768px) {
  /* .col-resizing-1-5 { width: 14%; }
  .col-resizing-2-5 { width: 20.83333%; } 
  .col-resizing-3-5 { width: 29.16667%; } 
  .col-resizing-4-5 { width: 37.5%; } 
  .col-resizing-5-5 { width: 45.83333%; } 
  .col-resizing-6-5 { width: 54.16667%; } 
  .col-resizing-7-5 { width: 62.5%; } 
  .col-resizing-8-5 { width: 70.83333%; }
  .col-resizing-9-5 { width: 79.16667%; }
  .col-resizing-10-5 { width: 87.5%; } 
  .col-resizing-11-5 { width: 95.8333%; } */
  
  .scroll-box{
    margin-bottom: 5%!important;
  }
  
  
  }
  
  @media only screen and (max-width: 768px) {
    .main-hero-technology {
      min-height: 50vh;
      background-position: left;
  }

  .main-hero-technology h1{
      font-size: calc(0.8rem + 2.3vw);
      line-height: 3rem;
      word-spacing: 0.4rem;
  } 
  
    .slider-arrow{
      display: none;
    }
  
    .img-scroller{
      width: 6rem;
    }
  
    .text-scroller{
      font-size: 2rem;
    }
  
    
    .content-head{
      font-size: 1.5rem!important;
      margin: 1rem 0rem!important;
    }
  
    .content{
      font-size: 1rem!important;
      margin:0 0 2rem 0!important;
    }
  
    .content-container{
      margin-bottom: 2rem!important;
      
    }
  
    .all-content-container{
      padding-top: 1rem!important;
      padding-bottom: 2rem!important;
    }

    .tech-scroller::-webkit-scrollbar-track{
    margin-left:2rem!important;
    margin-right:2rem!important;
    }
    
  }

  
@media only screen and (max-width: 576px) {
  
  .main-hero-technology h1{
    font-size: calc(0.7rem + 2.3vw);
    line-height: 3rem;
    word-spacing: 0rem;
} 
}