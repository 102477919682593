.thankyou-hero {
    background-image: url("../../../public/assets/thankyouimages/thank.png");
    background-repeat: no-repeat;
    min-height: 90vh;
    position: relative;
    background-size: cover;
    background-position: bottom;
    overflow: hidden;
}

.thankyou-hero h1{
    font-weight: 600;
    font-size: 3rem;
    letter-spacing: 0.2rem;
}

.thank-text h2{
    font-weight: 500;
    font-size: 2.5rem;
    letter-spacing: 0.2rem;
}

.color-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
}

.thanksexpertise-box{
    background-color: #00A692;
    border-radius: 30px;
    color: #FFFFFF;
}

@media only screen and (max-width: 768px) {
    .thankyou-hero {
        min-height: 50vh;
    }

    .thankyou-hero h1{
        font-size: calc(1.5rem + 3.3vw);
        
    }

    .thank-text h2{
        font-size: calc(1.5rem + 1vw);
    } 
}

@media only screen and (max-width: 425px){
    .thank-text h2{
        font-size: calc(1rem + 2vw);
    } 
}