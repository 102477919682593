@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800&display=swap');


body {
    overflow-x: hidden;
 }

.footbg{
    background: #FFDAA5;
}

.themfootbg{
    background: #FFDAA5;
}

.whitefootbg{
    background: #ffff;
}

.links a:hover{
    color:#C28A3F!important;
}

.footlabel, .links a, .test-div h1 {
    font-family: 'Inter', sans-serif!important;
    font-weight: 400;
}

.flaglabel {
    font-weight: 500;
    font-family: 'Inter', sans-serif!important;
}

hr{
    border: none;
    height: 1.5px;
    background: #363636!important;
}

.uselinks a {
    text-decoration: none!important;
    color: black!important;
}

.uselinks a:hover {
    color: #B4531C!important;
}

.footlink, .footmenu {
    margin-left: 1.7rem!important;
}

.humanssofttech {
    font-size: 1.2rem!important;
    font-family: 'Inter', sans-serif!important;
    font-weight: 700;
    word-spacing: 2px!important;
}

.office {
    line-height: 1.3rem;
    font-weight: 400;
}

.officeadd {
    margin-top: -0.5rem;
    line-height: 1.3rem;
    font-weight: 400;
}

.checkbtn{
    margin-left: 0.8rem;
}

.flags{
    border-radius: 0.2rem;
}

.test-div h1{
    letter-spacing: 0.1rem;
}

.foottouch {
    font-family: 'Inter', sans-serif!important;
    font-size: 50px;
    letter-spacing: 0.1rem;
    font-weight: 800!important;
    color:#B4531C;
}

.form-check-label {
    font-family: 'Inter', sans-serif!important;
    font-size: 14px!important;
}


/* FORM INPUT BOX GLOW */
.form-control:focus {
    border-color: #AD3E00!important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.050), 0 1px 8px rgb(237, 180, 148);
}

.footform {
    background-color: #F5F9FF;
    overflow: hidden;
    padding: 3rem 3rem;
    border:1px solid black;
    border-radius: 10px;
    height: auto;
}

.footform h3 {
    font-family: 'Inter', sans-serif!important;
    margin-top: -20px!important;
    margin-bottom: 2rem!important;
    font-weight: 400;
    text-align: center;
}

textarea, input {
 
    font-family: 'Inter', sans-serif!important;
    box-sizing: border-box!important;
    border: 1px solid #AD3E00!important;
    -webkit-transition: 0.5s!important;
    transition: 0.5s!important;
    outline: none!important;
  }

input:focus {
    border: 1px solid #AD3E00!important;
}

.footbutton {
    font-family: 'Inter', sans-serif!important;
    background-color: white!important;
    border: 1px solid #B4531C;
    border-radius: 10px;
    font-weight: 500;
    width: 8rem;
}

.footbutton:hover {
    color: white;
    background-color: #FF7D33!important;
}

.jugaad {
    display: none!important;
  }

@media only screen and (max-width: 992px) {
    .test-div{
        display: block!important;
        
        margin-bottom: 2rem!important;
    }


    .footform-mobile{
        justify-content: space-around!important;
      
    }
}

@media only screen and (max-width: 768px) {
 
    .footform h3 {
        margin-top: -30px!important;
        margin-bottom: 0rem!important;
        font-weight: 400;
        text-align: center;
    }
  
    .form-group, .form-check,.subbtn{
      
        margin-top: 2rem!important;
    }


    .test-div{
        display: block!important;
    }


    .footform-mobile{
        justify-content: space-around!important;
        
    }

    

    .copyright{
        font-size: 0.9rem!important;
    }
  }
  
  @media only screen and (max-width: 767px) {
  
    .footform {
        background-color: #F5F9FF;
        overflow: hidden;
        padding: 4rem 1rem;
        /* padding: 20px 20px; */
        border:1px solid black;
        border-radius: 10px;
        height: auto;
    }

    .footform h3{
        font-size: 1.2rem!important;
    }

    .form-row{
        margin-top: 2rem!important;
    }
    
    .form-control{
        padding: 0.2rem 0.7rem!important;
    }

    .form-group, .form-check,.subbtn{
        margin-top: 0rem!important;
    }

    .subbtn{
        margin-bottom: -1rem!important;
    }

    .checkbtn{
        margin-left: 2.2rem;
    }
  }

  @media only screen and (max-width: 679px) {
    .checkbtn{
        margin-left: 2rem;
    }
    
  }

 

  @media only screen and (max-width: 950px) {
    .terms{
        display: none!important;
    }

    .jugaad{
        display: block!important;
    }
  }


  /* ALIGN FOOTER IMAGES GRID */
@media only screen and (max-width: 700px) {
    
    .grid-container {
        display: grid!important;
        grid-template-columns: auto auto auto auto auto !important;
        overflow: hidden!important;
    }

    .footlink img{
        width: 30px!important;
    }
    
  

    .img-container{
        display: grid!important;
        grid-template-columns: auto auto auto auto!important;
        overflow: hidden!important;
    }

}

@media only screen and (max-width: 580px) {

    .kartik2, .stay-tuned{
        font-size: 0.8rem!important;
    }

    .footlink {
        margin-left: 1rem!important;
    }

    .footmenu {
        margin-left: 1rem!important;
    }

    .checkbtn{
        margin-left: 1.2rem;
    }

    .jugaad{
        font-size: 0.9rem!important;
    }

    .copyright{
        font-weight: 400!important;
        font-size: 0.8rem!important;
    }
    
    .email img{
        width: 30px!important;
    }

    .skype img{
        width: 20px!important;
    }
    .mobile-form {
        font-size: 0.9rem!important;
    }

    .grid-container {
       display: grid!important;
       grid-template-columns: auto auto auto!important;
       overflow: hidden!important;
   }
    .footlink img{
        width: 25px!important;
    }

    .img-container{
        display: grid!important;
        grid-template-columns: auto auto auto auto!important;
        overflow: hidden!important;
    }
}


