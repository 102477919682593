.privacy-hero {
    background-image: url("../../../public/assets/privacypolicyimages/lock.png");
    background-repeat: no-repeat;
    min-height: 90vh;
    position: relative;
    background-size: cover;
    background-position: bottom;
    overflow: hidden;
}

.privacy-hero h1{
    font-weight: 600;
    font-size: 3rem;
}

.color-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
}

.privacy-text{
    font-weight: 700;

}

.privacy-lines{
    margin-bottom: 0%!important;
    padding-top: 0.5rem;
}

.privacy-vc{
    width: 1.25rem;
}

@media only screen and (max-width: 768px) {
    .privacy-hero {
        min-height: 50vh;
    }

    .privacy-hero h1{
        font-size: calc(0.8rem + 2.3vw);
    }

    .privacy-lines{
        padding-top: 0.8rem!important;
    }
}